/**
 * klib页面参数和枚举等数据变量
 */
export const QUERY_DATA_OBJ = {
  endTime: '',
  isAsc: '',
  orderByColumn: '',
  pageNum: 1,
  pageSize: 10,
  pages: 1,
  pubStatus: '', // 0:待发布 1:发布 9:撤回
  startTime: '',
  version: '', // 版本号
  pubDateArr: []
}

// 编辑klib
export const EDIT_DATA_OBJ = {
  comments: '', // 备注
  downloadLinkList: [],
  id: '',
  passwdStr: '', // 下载口令拼接
  pubDate: '', // klib发布日期
  pubDateArr: [],
  pubStatus: '', // klib发布状态 0:待发布 1:发布 9:撤回
  pubStatusStr: '', // 发布状态的中文
  status: '', // 状态（0正常 1停用）
  urlStr: '', // 下载链接拼接
  vcMd5: '', // 文件MD5校验码
  version: '', // klib版本号
}
// 编辑klib，downloadLinkList（下载链接与口令）
export const DOWNLOAD_LINK_PASSWORD_OBJ = {
  isPrimary: 0, // 是否为首选项 1为首选项
  passwd: '', // 下载口令
  url: '', // 下载地址
  isEdit: false
}
// klib状态集合
export const STATUS_OPTIONS = [
  {
    value: '',
    label: '全部'
  },
  {
    value: 0,
    label: '待发布'
  },
  {
    value: 1,
    label: '已发布'
  },
  {
    value: 9,
    label: '已撤回'
  }
]