var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      ref: "drawerRef",
      attrs: {
        visible: _vm.drawerVisible,
        direction: "rtl",
        "custom-class": "common-question-wrap",
        size: 450,
      },
      on: { close: _vm.handleClose },
    },
    [
      _c("div", { attrs: { slot: "title" }, slot: "title" }, [
        _c("i", {
          staticClass: "el-icon-question",
          staticStyle: { color: "#654CEE" },
        }),
        _vm._v("下载常见问题Q&A "),
      ]),
      _c("section", { staticClass: "question-wrap" }, [
        _c("h4", { staticClass: "title" }, [
          _vm._v("Q1：文件解压失败是什么原因？"),
        ]),
        _c("div", { staticClass: "question-div" }, [
          _c("p", { staticClass: "question" }, [
            _vm._v(
              "A1：下载文件无法解压可能是下载过程中文件损坏造成。可以按如下步骤验证文件是否损坏："
            ),
          ]),
          _c("p", { staticClass: "primary-text" }, [_vm._v("解决方法：")]),
          _c("p", { staticClass: "question" }, [
            _vm._v(" 1.运行MD5.bat工具，为下载文件生成MD5校验码。 "),
          ]),
          _c("p", { staticClass: "question" }, [
            _c("img", {
              staticClass: "img-full-width",
              attrs: {
                src: require("../../../assets/images/rationalDrugUse/klib/question6.png"),
              },
            }),
          ]),
          _c("p", { staticClass: "question" }, [
            _c("img", {
              staticClass: "img-full-width",
              attrs: {
                src: require("../../../assets/images/rationalDrugUse/klib/question7.png"),
              },
            }),
          ]),
          _c("p", { staticClass: "question" }, [
            _vm._v(" 2.对比生成的MD5校验码与md5.txt文件中的内容是否一样。 "),
          ]),
          _c(
            "p",
            { staticClass: "question", staticStyle: { "text-indent": "2em" } },
            [
              _vm._v(
                " a) 如果内容不一致，可确定是下载中文件损坏，请重新下载文件； "
              ),
            ]
          ),
          _c(
            "p",
            { staticClass: "question", staticStyle: { "text-indent": "2em" } },
            [_vm._v(" b) 如果内容一致，请反馈客服协助进行问题处理。 ")]
          ),
        ]),
      ]),
      _c("section", { staticClass: "question-wrap" }, [
        _c("h4", { staticClass: "title" }, [_vm._v("Q2：下载速度慢怎么办？")]),
        _c("div", { staticClass: "question-div" }, [
          _c("p", { staticClass: "question" }, [
            _vm._v(
              "A2：首先请检查网络，看连接是否正常；若网络正常，针对百度网盘非会员用户，有以下速度提升小技巧："
            ),
          ]),
          _c("p", { staticClass: "primary-text" }, [
            _vm._v("步骤1：登陆电脑客户端，打开右上角的设置按钮"),
          ]),
          _c("p", { staticClass: "question" }, [
            _c("img", {
              attrs: {
                src: require("../../../assets/images/rationalDrugUse/klib/question1.png"),
              },
            }),
          ]),
          _c("p", { staticClass: "primary-text" }, [_vm._v("步骤2：选择设置")]),
          _c("p", { staticClass: "question" }, [
            _c("img", {
              attrs: {
                src: require("../../../assets/images/rationalDrugUse/klib/question2.png"),
              },
            }),
          ]),
          _c("p", { staticClass: "primary-text" }, [
            _vm._v("步骤3：左侧菜单栏选择传输，找到下载提速，点击旁边的去开启"),
          ]),
          _c("p", { staticClass: "question" }, [
            _c("img", {
              attrs: {
                src: require("../../../assets/images/rationalDrugUse/klib/question3.png"),
              },
            }),
          ]),
          _c("p", { staticClass: "primary-text" }, [
            _vm._v("步骤4：勾选我同意，点击开启优化速率。"),
          ]),
          _c("p", { staticClass: "question" }, [
            _c("img", {
              attrs: {
                src: require("../../../assets/images/rationalDrugUse/klib/question4.png"),
              },
            }),
          ]),
          _c("p", { staticClass: "primary-text" }, [
            _vm._v("步骤5：默认选择“优化速率模式”，点击“确定”，操作完成。"),
          ]),
          _c("p", { staticClass: "question" }, [
            _c("img", {
              attrs: {
                src: require("../../../assets/images/rationalDrugUse/klib/question5.png"),
              },
            }),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }