<!--Klib的首页-->
<template>
  <el-container class="layout-container klib-home-page-container">
    <el-header class="klib-home-header">
      <div class="klib-logo-wrap">
        <img src="../../src/assets/images/rationalDrugUse/logo.png" />
      </div>
      <h3 class="klib-home-header-title">药品知识库公共数据更新包</h3>
    </el-header>
    <el-main class="klib-home-main scrollWrap">
      <section class="latest-version-section">
        <div class="latest-version-div">
          <h3 class="text-title text">
            {{ latestData.version }}
            <svg-icon icon-class="new" class="new-svg-icon" />
          </h3>
          <el-form :inline="false" :model="latestData" size="small" :label-width="labelWidth" label-position="left" class="search-form-wrap">
            <el-form-item label="发布日期：">
              <span>{{ latestData.pubDate }}</span>
            </el-form-item>
            <el-form-item label="md5：">
              <span>{{ latestData.vcMd5 }}</span>
            </el-form-item>
            <el-form-item label="下载地址：" size="medium" class="download-form-item">
              <el-radio-group v-model="linkRadio" size="small" @change="linkChange" class="link-radio-group">
                <el-radio-button
                  v-for="(item, index) in latestData.downloadLinkList"
                  :label="item.url"
                  :key="index"
                  class="flat-btn-label">
                  <span v-if="item.isPrimary === 1">主地址</span>
                  <span v-if="item.isPrimary === 0">备用地址{{ index }}</span>
                </el-radio-button>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="" class="mini-form-item">
              <span class="text">地址：</span>
              <el-link :underline="false" :href="currentLink.url" type="primary" target="_blank">{{ currentLink.url }}</el-link>
            </el-form-item>
            <el-form-item label="" class="mini-form-item">
              <span class="text">口令：</span>
              <span class="text">{{ currentLink.passwd }}</span>
            </el-form-item>
            <el-form-item label="" class="download-form-item large-margin-btom">
              <el-button type="primary" @click="onDownload">立即下载</el-button>
              <el-button icon="el-icon-info" class="common-question-icon" type="text" @click="onShowCommonQuestion">常见问题</el-button>
            </el-form-item>
          </el-form>
        </div>
      </section>
      <section class="history-btn-section">
        <el-button
          v-if="isShowMore && hasHistoryData"
          type="text"
          class="btn-el"
          @click="onShowHistory">
          更多历史版本
          <i class="el-icon-arrow-right el-icon--right"></i>
        </el-button>
        <el-button
          v-else-if="!isShowMore && hasHistoryData"
          type="text"
          class="btn-el"
          @click="onHideMoreHistory">
          收起历史版本
          <i class="el-icon-arrow-up el-icon--right"></i>
        </el-button>
      </section>
      <section class="history-version-section" v-show="!isUnfoldMore">
        <h4 class="history-title">历史更新版本</h4>
        <transition name="fade">
          <el-timeline>
            <el-timeline-item
              v-for="(item, index) in historyDataList"
              :key="index"
              :timestamp="item.version"
              placement="top">
              <!-- <div slot="dot">
                {{item.version}}
              </div> -->
              <p class="history-item-version">版本号：{{item.version}}</p>
              <el-card class="history-card">
                <el-form :inline="false" :model="item" size="small" label-width="21%" class="search-form-wrap">
                  <el-form-item label="md5：">
                    <!-- <span>{{ latestData.vcMd5 }}</span> -->
                    <span>{{ item.vcMd5 }}</span>
                  </el-form-item>
                  <div v-for="(link, linkIndex) in item.downloadLinkList" :key="linkIndex">
                    <el-form-item :label="'下载地址' + (linkIndex + 1) + '：'">
                      <el-link :underline="false" :href="link.url" type="primary" target="_blank">
                        <!-- {{ currentLink.url }} -->
                        {{ link.url }}
                      </el-link>
                    </el-form-item>
                    <el-form-item :label="'下载口令' + (linkIndex + 1) + '：'">
                      <span>{{ link.passwd }}</span>
                    </el-form-item>
                  </div>
                </el-form>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </transition>
        <p v-if="isShowMore" class="tip-text">下拉加载更多</p>
        <p v-else-if="!isShowMore" class="tip-text">已经全部加载</p>
      </section>
      <common-problem-vue v-if="drawerVisible" :drawer-visible.sync="drawerVisible" />
    </el-main>
    <!-- <el-footer v-if="hasHistoryData" class="klib-home-footer">
      <section class="show-more-history-btn-section">
        <el-button
          v-if="isShowMore"
          icon="el-icon-arrow-down"
          type="text"
          @click="onShowHistory">
          更多历史版本
        </el-button>
        <el-button
          v-else
          icon="el-icon-arrow-up"
          type="text"
          @click="onHideMoreHistory">
          收起历史版本
        </el-button>
      </section>
    </el-footer> -->
  </el-container>
</template>
<script>
  import CommonProblemVue from './rationalDrugUse/klibComponents/CommonProblem.vue'
  import {
    EDIT_DATA_OBJ,
    QUERY_DATA_OBJ,
    DOWNLOAD_LINK_PASSWORD_OBJ
  } from '@/utils/rationalDrugUse/klib'
  import {
    getKlibNewwstVersionApi,
    getKlibListApi
  } from '@/api/rationalDrugUse/klib'
  export default {
    filters: {
    },
    components: {
      CommonProblemVue
    },
    computed: {
    },
    watch: {
    },
    data() {
      return {
        latestData: this.$deepClone(EDIT_DATA_OBJ),
        currentLink: this.$deepClone(DOWNLOAD_LINK_PASSWORD_OBJ),
        linkRadio: '',
        drawerVisible: false,
        historyDataList: [],
        oriResult: [],
        queryObj: this.$deepClone(QUERY_DATA_OBJ),
        total: 0,
        isShowMore: true,
        isUnfoldMore: true,
        neverFetchData: true,
        hasHistoryData: false,
        labelWidth: '95px'
      }
    },
    async created() {
      console.log('klibHomeMain created')
    },
    async mounted() {
      //mounted监听处理
      this.$nextTick (() => {
        if (document.querySelector ('.scrollWrap')) {
          const selectWrap = document.querySelector ('.scrollWrap')
          selectWrap.addEventListener ('scroll', this.scrollLoadMore)
        }
      })
    },
    async activated() {
      console.log('klibHomeMain activated')
      await this.getLatestData()
    },
    destoryed() {
    },
    deactivated() {
    },
    methods: {
      linkChange(val) {
        this.currentLink = this.latestData.downloadLinkList.find(item => item.url === val)
      },
      onShowCommonQuestion() {
        this.drawerVisible = true
      },
      // 获取最新版本
      async getLatestData() {
        let res = await getKlibNewwstVersionApi()
        if (res.code === 200) {
          this.latestData = res.data || EDIT_DATA_OBJ
          this.linkRadio = this.latestData.downloadLinkList.find(item => item.isPrimary === 1).url
          this.currentLink = this.latestData.downloadLinkList.find(item => item.url === this.linkRadio)
        }
        // 获取历史版本数据，看是否有历史版本数据，没有则不提示用户去获取历史版本数据
        this.queryObj.pubStatus = 1
        let historyRes = await getKlibListApi(this.queryObj)
        if (historyRes.code === 200) {
          let data = historyRes.data.rows || []
          if (data.length > 1) {
            this.hasHistoryData = true
          } else {
            this.hasHistoryData = false
          }
        }
      },
      async getHistoryData() {
        this.neverFetchData = false
        this.queryObj.pubStatus = 1
        let res = await getKlibListApi(this.queryObj)
        if (res.code === 200) {
          this.total = res.data.total - 1 // 减一是去除最新版本的那条数据
          this.queryObj.pages = Math.ceil(this.total / this.queryObj.pageSize)
          // console.log(this.queryObj.pages)
          let data = res.data.rows || []
          data = data.filter(item => item.id !== this.latestData.id)
          this.historyDataList = this.oriResult.concat(data)
          this.oriResult = this.historyDataList
          // console.log(this.historyDataList)
          if (this.historyDataList.length >= this.total) {
            this.isShowMore = false
          } else {
            this.isShowMore = true
          }
        }
      },
      scrollLoadMore() {
        let scrollWrap = document.querySelector ('.scrollWrap')
        const currentScrollTop = scrollWrap.scrollTop
        const currentOffsetHeight = scrollWrap.scrollHeight
        const currentClientHeight = scrollWrap.clientHeight
        // console.log(currentScrollTop)
        // console.log(currentOffsetHeight)
        // console.log(currentClientHeight)
        if ((currentScrollTop + currentClientHeight >= currentOffsetHeight) && (this.queryObj.pageNum < this.queryObj.pages)) {
          //到底部了 重新请求数据
          this.queryObj.pageNum++ //页码++
          this.getHistoryData()
        }
      },
      async onShowHistory() {
        if (this.neverFetchData) {
          await this.getHistoryData()
        }
        this.isUnfoldMore = false
        this.isShowMore = false
      },
      onHideMoreHistory() {
        this.isShowMore = true
        this.isUnfoldMore = true
      },
      onDownload() {
        window.open(this.linkRadio, '_blank')
      }
    }
  }
</script>
<style lang="scss" scoped>
  .tip-text {
    color: #fff;
    font-size: 14px;
    text-align: center;
  }
  .el-button--primary {
    background-color: #654CEE;
  }
  .el-link.el-link--primary {
    color: #5BCFFF;
    font-size: 18px;
    &:hover {
      color: #99ACFF;
    }
  }
  .el-button--primary.is-plain {
    background-color: #F4F6FE;
    color: #654CEE;
    border: none;
    &:hover {
      background-color: #F3F1FF;
    }
  }
  .el-button--text {
    color: #fff;
  }
  ::v-deep.flat-btn-label .el-radio-button__inner {
    width: 120px;
    padding: 12px 0px;
  }
  ::v-deep.el-form-item {
    position: relative;
    margin-bottom: 5px !important;
    .el-form-item__label {
      /* position: absolute;
      top: 50%;
      transform: translateY(-50%);
      line-height: 18px; */
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      padding: 0 5px 0 0 !important;
      line-height: 40px;
    }
    
    .el-form-item__content {
      color: #fff;
      font-size: 18px;
    }
    .text {
      color: #fff;
    }
    .el-date-editor.el-date-editor{
      width: 100% !important;
    }
    .link-radio-group .el-radio-button__orig-radio:checked + .el-radio-button__inner {
      border: none;
      /* background: #E3DEFC;
      color: #654CEE; */
      background: #99ACFF;
      color: #fff;
    }
    .el-button--primary {
      /* background-color: #654CEE; */
      width: 120px;
      height: 40px;
      background: #FFFFFF;
      box-shadow: 0px 0px 4px 0px rgba(192,208,255,0.75);
      opacity: 0.89;
      border-radius: 4px;
      color: #333;
      font-size: 14px;
    }
    .common-question-icon.el-button--text {
      /* color: #FFB232; */
      color: #E7C81B;
    }
    .el-button.common-question-icon [class*=el-icon-] + span {
      font-family: PingFang SC;
      font-weight: 400;
      /* color: #999999; */
      color: #FFF;
    }
    .el-radio-button__inner {
      border: none;
      background-color: #6A4DFF;
      color: #fff;
    }
  }
  ::v-deep .el-form-item.download-form-item {
    margin-top: 15px;
    margin-bottom: 18px !important;
  }
  ::v-deep .el-form-item.download-form-item.large-margin-btom {
    margin-top: 25px;
  }
  
  ::v-deep .mini-form-item .el-form-item__content {
    line-height: 24px;
  }
  .klib-home-page-container {
    /* background: #ffff; */
    /* width: 62.5%;
    margin: 0 auto;
    border-radius: 0px 10px 0px 0px;
    min-width: 800px; */
    background-image: url(../assets/images/rationalDrugUse/klib/klib-bag.png);
    background-size: 675px 508px;
    background-repeat: no-repeat;
    background-position: 90% 161px;
    .klib-home-header {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: auto !important;
      padding: 0;
      padding-top: 60px;
      margin-left: calc(28% - 20px);
      .klib-logo-wrap {
        /* width: 70px;
        height: 70px;
        background: #E8ECFC;
        box-shadow: 0px 0px 8px 0px rgba(112,112,255,0.26);
        opacity: 0.99;
        border-radius: 50%; */
        position: relative;
        img {
          /* position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%); */
          width: 72px;
          height: 52px;
        }
      }
      .klib-home-header-title {
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        text-shadow: 0px 0px 4px rgba(70,113,232,0.75);
        margin-top: 17px;
      }
    }
    .klib-home-main {
      overflow: inherit;
      /* background: #fff; */
      padding-top: 72px;
      .latest-version-section {
        /* margin-left: calc(18% - 20px); */
        .latest-version-div {
          /* width: 75%;
          margin: 0 auto;
          padding: 120px 120px;
          background-color: #F7F6FD;
          border-radius: 10px; */
          .text-title {
            margin-left: calc(18% - 33px);
            font-size: 26px;
            font-family: PingFang SC;
            font-weight: 600;
            color: #FFFFFF;
            text-shadow: 0px 0px 4px rgba(70,113,232,0.75);
            vertical-align: middle;
            position: relative;
            margin-bottom: 36px;
            &::before {
              content: '';
              display: inline-block;
              width: 8px;
              height: 8px;
              background: #B7EAFF;
              border-radius: 4px;
              vertical-align: middle;
            }
            .new-svg-icon {
              width: 40px;
              height: 32px;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }
          }
          .el-form {
            margin-left: calc(18% - 20px);
          }
        }
      }
      .show-more-history-btn-section {
        text-align: center;
        margin-top: 10px;
        position: fixed;
        bottom: 40px;
        width: 80%;
        left: 50%;
        transform: translateX(-50%);
      }
      .history-btn-section {
        margin-left: calc(18% - 20px);
        margin-top: 20px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
      }
      .history-version-section {
        /* margin: 0 auto; */
        margin: 60px 0 0 0;
        margin-left: calc(18% - 20px);
        min-width: 35%;
        width: 40%;
        margin-top: 20px;
        .history-title {
          font-size: 20px;
          line-height: 20px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #FFFFFF;
          vertical-align: middle;
          margin-bottom: 20px;
          &::before {
            content: '';
            display: inline-block;
            vertical-align: top;
            width: 4px;
            height: 20px;
            background: #B7EAFF;
            border-radius: 2px;
            margin-right: 5px;
          }
        }
        .history-card {
          background: #7263F3;
          border-radius: 6px;
          border: none;
          box-shadow: none;
        }
        ::v-deep.el-timeline-item__timestamp {
          color: #fff;
          font-size: 16px;
        }
      }
    }
    .klib-home-footer {
      .show-more-history-btn-section {
        text-align: center;
        margin-top: 10px;
        /* position: fixed;
        bottom: 40px;
        width: 80%;
        left: 50%;
        transform: translateX(-50%); */
      }
    }
  }
  .history-item-version {
    position: absolute;
    right: 0;
    top: 0px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #fff;
  }
  .el-card {
    background-color: #F7F6FD;
    border-radius: 10px;
  }
  @import '../assets/styles/rationalDrugUse/home.scss'
</style>
