<!--klib下载常见问题-->
<template>
  <el-drawer
    :visible="drawerVisible"
    direction="rtl"
    @close="handleClose"
    custom-class="common-question-wrap"
    :size="450"
    ref="drawerRef">
    <div slot="title">
      <i class="el-icon-question" style="color: #654CEE;"></i>下载常见问题Q&A
    </div>
    <section class="question-wrap">
      <h4 class="title">Q1：文件解压失败是什么原因？</h4>
      <div class="question-div">
        <p class="question">A1：下载文件无法解压可能是下载过程中文件损坏造成。可以按如下步骤验证文件是否损坏：</p>
        <p class="primary-text">解决方法：</p>
        <p class="question">
          1.运行MD5.bat工具，为下载文件生成MD5校验码。
        </p>
        <p class="question">
          <img src="../../../assets/images/rationalDrugUse/klib/question6.png" class="img-full-width" />
        </p>
        <p class="question">
          <img src="../../../assets/images/rationalDrugUse/klib/question7.png" class="img-full-width" />
        </p>
        <p class="question">
          2.对比生成的MD5校验码与md5.txt文件中的内容是否一样。
        </p>
        <p class="question" style="text-indent:2em;">
          a) 如果内容不一致，可确定是下载中文件损坏，请重新下载文件；
        </p>
        <p class="question" style="text-indent:2em;">
          b) 如果内容一致，请反馈客服协助进行问题处理。
        </p>
      </div>
    </section>
    <section class="question-wrap">
      <h4 class="title">Q2：下载速度慢怎么办？</h4>
      <div class="question-div">
        <p class="question">A2：首先请检查网络，看连接是否正常；若网络正常，针对百度网盘非会员用户，有以下速度提升小技巧：</p>
        <p class="primary-text">步骤1：登陆电脑客户端，打开右上角的设置按钮</p>
        <p class="question">
          <img src="../../../assets/images/rationalDrugUse/klib/question1.png" />
        </p>
        <p class="primary-text">步骤2：选择设置</p>
        <p class="question">
          <img src="../../../assets/images/rationalDrugUse/klib/question2.png" />
        </p>
        <p class="primary-text">步骤3：左侧菜单栏选择传输，找到下载提速，点击旁边的去开启</p>
        <p class="question">
          <img src="../../../assets/images/rationalDrugUse/klib/question3.png" />
        </p>
        <p class="primary-text">步骤4：勾选我同意，点击开启优化速率。</p>
        <p class="question">
          <img src="../../../assets/images/rationalDrugUse/klib/question4.png" />
        </p>
        <p class="primary-text">步骤5：默认选择“优化速率模式”，点击“确定”，操作完成。</p>
        <p class="question">
          <img src="../../../assets/images/rationalDrugUse/klib/question5.png" />
        </p>
      </div>
    </section>
  </el-drawer>
</template>
<script>
  export default {
    props: {
      drawerVisible: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        
      }
    },
    methods: {
      handleClose(done) {
        this.$emit('update:drawerVisible', false)
        console.log('jj')
        // done()
        // this.$refs.drawerRef.closeDrawer()
      }
    }
  }
</script>
<style lang="scss">
  .common-question-wrap {
    .img-full-width {
      width: 100%;
    }
    .el-drawer__header {
      border-bottom: 1px solid #E1DFE3;
      padding: 12px 20px !important;
    }
    .el-drawer__body {
      padding-left: 16px;
      padding-right: 10px;
      padding-bottom: 20px;
      .question-wrap {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
        .question-div {
          background: #F4F2FC;
          border-radius: 6px;
          padding: 10px;
          font-size: 14px;
          color: #666;
          line-height: 26px;
        }
        .title {
          font-size: 16px;
          font-family: PingFang SC;
          font-weight: 600;
          color: #333333;
          margin-bottom: 10px;
          &::before {
            content: '';
            display: inline-block;
            width: 8px;
            height: 8px;
            background: #9D8EED;
            border-radius: 4px;
            margin-right: 5px;
          }
        }
        .primary-text {
          color: #5747B3;
          margin: 10px 0;
        }
      }
    }
  }
</style>