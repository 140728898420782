import request from '@/utils/request'

// 查询klib查询列表
export function getKlibListApi(data) {
  return request({
    url: '/dm/klib/list',
    method: 'get',
    params: data
  })
}

// 获取最新版本数据
export function getKlibNewwstVersionApi() {
  return request({
    url: '/dm/klib/newest',
    method: 'get'
  })
}

// 新增-编辑Klib
export function editKlibApi(data) {
  return request({
    url: '/dm/klib/save',
    method: 'put',
    data: data
  })
}

// 状态变更
export function editKlibStatusApi(data) {
  return request({
    url: '/dm/klib/status',
    method: 'put',
    params: data
  })
}
// 删除klib信息
export function delKlibApi(data) {
  return request({
    url: '/dm/klib/' + data.id,
    method: 'delete',
  })
}
