var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "layout-container klib-home-page-container" },
    [
      _c("el-header", { staticClass: "klib-home-header" }, [
        _c("div", { staticClass: "klib-logo-wrap" }, [
          _c("img", {
            attrs: {
              src: require("../../src/assets/images/rationalDrugUse/logo.png"),
            },
          }),
        ]),
        _c("h3", { staticClass: "klib-home-header-title" }, [
          _vm._v("药品知识库公共数据更新包"),
        ]),
      ]),
      _c(
        "el-main",
        { staticClass: "klib-home-main scrollWrap" },
        [
          _c("section", { staticClass: "latest-version-section" }, [
            _c(
              "div",
              { staticClass: "latest-version-div" },
              [
                _c(
                  "h3",
                  { staticClass: "text-title text" },
                  [
                    _vm._v(" " + _vm._s(_vm.latestData.version) + " "),
                    _c("svg-icon", {
                      staticClass: "new-svg-icon",
                      attrs: { "icon-class": "new" },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form",
                  {
                    staticClass: "search-form-wrap",
                    attrs: {
                      inline: false,
                      model: _vm.latestData,
                      size: "small",
                      "label-width": _vm.labelWidth,
                      "label-position": "left",
                    },
                  },
                  [
                    _c("el-form-item", { attrs: { label: "发布日期：" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.latestData.pubDate))]),
                    ]),
                    _c("el-form-item", { attrs: { label: "md5：" } }, [
                      _c("span", [_vm._v(_vm._s(_vm.latestData.vcMd5))]),
                    ]),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "download-form-item",
                        attrs: { label: "下载地址：", size: "medium" },
                      },
                      [
                        _c(
                          "el-radio-group",
                          {
                            staticClass: "link-radio-group",
                            attrs: { size: "small" },
                            on: { change: _vm.linkChange },
                            model: {
                              value: _vm.linkRadio,
                              callback: function ($$v) {
                                _vm.linkRadio = $$v
                              },
                              expression: "linkRadio",
                            },
                          },
                          _vm._l(
                            _vm.latestData.downloadLinkList,
                            function (item, index) {
                              return _c(
                                "el-radio-button",
                                {
                                  key: index,
                                  staticClass: "flat-btn-label",
                                  attrs: { label: item.url },
                                },
                                [
                                  item.isPrimary === 1
                                    ? _c("span", [_vm._v("主地址")])
                                    : _vm._e(),
                                  item.isPrimary === 0
                                    ? _c("span", [
                                        _vm._v("备用地址" + _vm._s(index)),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            }
                          ),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { staticClass: "mini-form-item", attrs: { label: "" } },
                      [
                        _c("span", { staticClass: "text" }, [_vm._v("地址：")]),
                        _c(
                          "el-link",
                          {
                            attrs: {
                              underline: false,
                              href: _vm.currentLink.url,
                              type: "primary",
                              target: "_blank",
                            },
                          },
                          [_vm._v(_vm._s(_vm.currentLink.url))]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-form-item",
                      { staticClass: "mini-form-item", attrs: { label: "" } },
                      [
                        _c("span", { staticClass: "text" }, [_vm._v("口令：")]),
                        _c("span", { staticClass: "text" }, [
                          _vm._v(_vm._s(_vm.currentLink.passwd)),
                        ]),
                      ]
                    ),
                    _c(
                      "el-form-item",
                      {
                        staticClass: "download-form-item large-margin-btom",
                        attrs: { label: "" },
                      },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: { click: _vm.onDownload },
                          },
                          [_vm._v("立即下载")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "common-question-icon",
                            attrs: { icon: "el-icon-info", type: "text" },
                            on: { click: _vm.onShowCommonQuestion },
                          },
                          [_vm._v("常见问题")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c(
            "section",
            { staticClass: "history-btn-section" },
            [
              _vm.isShowMore && _vm.hasHistoryData
                ? _c(
                    "el-button",
                    {
                      staticClass: "btn-el",
                      attrs: { type: "text" },
                      on: { click: _vm.onShowHistory },
                    },
                    [
                      _vm._v(" 更多历史版本 "),
                      _c("i", {
                        staticClass: "el-icon-arrow-right el-icon--right",
                      }),
                    ]
                  )
                : !_vm.isShowMore && _vm.hasHistoryData
                ? _c(
                    "el-button",
                    {
                      staticClass: "btn-el",
                      attrs: { type: "text" },
                      on: { click: _vm.onHideMoreHistory },
                    },
                    [
                      _vm._v(" 收起历史版本 "),
                      _c("i", {
                        staticClass: "el-icon-arrow-up el-icon--right",
                      }),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "section",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isUnfoldMore,
                  expression: "!isUnfoldMore",
                },
              ],
              staticClass: "history-version-section",
            },
            [
              _c("h4", { staticClass: "history-title" }, [
                _vm._v("历史更新版本"),
              ]),
              _c(
                "transition",
                { attrs: { name: "fade" } },
                [
                  _c(
                    "el-timeline",
                    _vm._l(_vm.historyDataList, function (item, index) {
                      return _c(
                        "el-timeline-item",
                        {
                          key: index,
                          attrs: { timestamp: item.version, placement: "top" },
                        },
                        [
                          _c("p", { staticClass: "history-item-version" }, [
                            _vm._v("版本号：" + _vm._s(item.version)),
                          ]),
                          _c(
                            "el-card",
                            { staticClass: "history-card" },
                            [
                              _c(
                                "el-form",
                                {
                                  staticClass: "search-form-wrap",
                                  attrs: {
                                    inline: false,
                                    model: item,
                                    size: "small",
                                    "label-width": "21%",
                                  },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "md5：" } },
                                    [_c("span", [_vm._v(_vm._s(item.vcMd5))])]
                                  ),
                                  _vm._l(
                                    item.downloadLinkList,
                                    function (link, linkIndex) {
                                      return _c(
                                        "div",
                                        { key: linkIndex },
                                        [
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label:
                                                  "下载地址" +
                                                  (linkIndex + 1) +
                                                  "：",
                                              },
                                            },
                                            [
                                              _c(
                                                "el-link",
                                                {
                                                  attrs: {
                                                    underline: false,
                                                    href: link.url,
                                                    type: "primary",
                                                    target: "_blank",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " + _vm._s(link.url) + " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "el-form-item",
                                            {
                                              attrs: {
                                                label:
                                                  "下载口令" +
                                                  (linkIndex + 1) +
                                                  "：",
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(link.passwd)),
                                              ]),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.isShowMore
                ? _c("p", { staticClass: "tip-text" }, [_vm._v("下拉加载更多")])
                : !_vm.isShowMore
                ? _c("p", { staticClass: "tip-text" }, [_vm._v("已经全部加载")])
                : _vm._e(),
            ],
            1
          ),
          _vm.drawerVisible
            ? _c("common-problem-vue", {
                attrs: { "drawer-visible": _vm.drawerVisible },
                on: {
                  "update:drawerVisible": function ($event) {
                    _vm.drawerVisible = $event
                  },
                  "update:drawer-visible": function ($event) {
                    _vm.drawerVisible = $event
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }